import { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import CloseIcon from '@mui/icons-material/Close'
import Fade from '@mui/material/Fade'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { useUpdateEquipmentMutation } from '../equipments/equipmentsApiSlice'
import { useGetDistributorsQuery } from '../distributors/distributorsApiSlice'
import { useGetCustomersQuery } from '../customers/customersApiSlice'
import { useGetUnitsQuery } from '../units/unitsApiSlice'
import { toast } from 'react-toastify'
import { useModalContext } from '../../hooks/useModalContext'
import { useLazyGetExpirationDateQuery } from '../calc/calcApiSlice'
import CustomCheckBox from '../../components/CustomCheckBox'
import CustomDatePicker from '../../components/CustomDatePicker'
import CustomRadioButton from '../../components/CustomRadioButton'
import CustomTextInput from '../../components/CustomTextInput'
import CustomWindowedSelect from '../../components/CustomWindowedSelect'
import dayjs from 'dayjs'
import { useTheme } from '@mui/material'

const UpdateEquipmentForm = () => {
  const theme = useTheme()
  const { handleCloseModal, pageTitle, selectedRow: equipment, openModals } = useModalContext()
  const [updateEquipment] = useUpdateEquipmentMutation()
  const { data: distributors, isLoading: isLoadingDistributors, isSuccess: isSuccessDistributors, isFetching: isFetchingDistributors } = useGetDistributorsQuery('distributorsList')
  const { data: customers, isLoading: isLoadingCustomers, isSuccess: isSuccessCustomers } = useGetCustomersQuery('customersList')
  const { data: units, isLoading: isLoadingUnits, isSuccess: isSuccessUnits } = useGetUnitsQuery('unitsList')
  const [pending, setIsPending] = useState(false)
  const [compressorHorsepower, setCompressorHorsepower] = useState(equipment?.compressorHorsepower)
  const [customerRef, setCustomerRef] = useState(equipment?.customer)
  const [customerType, setCustomerType] = useState(equipment?.customerType)
  const [distributorRef, setDistributorRef] = useState(equipment?.distributor)
  const [expirationDate, setExpirationDate] = useState(equipment?.expirationDate)
  const [installationDate, setInstallationDate] = useState(equipment?.installationDate ? new Date(equipment?.installationDate) : null)
  const [isPreRegistered, setIsPreRegistered] = useState(equipment?.isPreRegistered)
  const [isRecycled, setIsRecycled] = useState(equipment?.isRecycled)
  const [isRegistered, setIsRegistered] = useState(equipment?.isRegistered)
  const [purchaseOrder, setPurchaseOrder] = useState(equipment?.purchaseOrder)
  const [preRegistrantFirstName, setPreRegistrantFirstName] = useState(equipment?.preRegistrantFirstName || '')
  const [preRegistrantMoneyLoaded, setPreRegistrantMoneyLoaded] = useState(equipment?.preRegistrantMoneyLoaded ? new Date(equipment?.preRegistrantMoneyLoaded) : null)
  const [preRegistrantAward, setPreRegistrantAward] = useState(equipment?.preRegistrantAward || '')
  const [preRegistrantCardOrdered, setPreRegistrantCardOrdered] = useState(equipment?.preRegistrantCardOrdered ? new Date(equipment?.preRegistrantCardOrdered) : null)
  const [preRegistrantEmail, setPreRegistrantEmail] = useState(equipment?.preRegistrantEmail || '')
  const [preRegistrantLastName, setPreRegistrantLastName] = useState(equipment?.preRegistrantLastName || '')
  const [preRegistrationDate, setPreRegistrationDate] = useState(equipment?.preRegistrationDate || '')
  const [registrantAward, setRegistrantAward] = useState(equipment?.registrantAward || '')
  const [registrantCardOrdered, setRegistrantCardOrdered] = useState(equipment?.registrantCardOrdered ? new Date(equipment?.registrantCardOrdered) : null)
  const [registrantEmail, setRegistrantEmail] = useState(equipment?.registrantEmail || '')
  const [registrantFirstName, setRegistrantFirstName] = useState(equipment?.registrantFirstName || '')
  const [registrantLastName, setRegistrantLastName] = useState(equipment?.registrantLastName || '')
  const [registrantMoneyLoaded, setRegistrantMoneyLoaded] = useState(equipment?.registrantMoneyLoaded ? new Date(equipment?.registrantMoneyLoaded) : null)
  const [registrationDate, setRegistrationDate] = useState(equipment?.registrationDate ? new Date(equipment?.registrationDate) : null)
  const [salesAward, setSalesAward] = useState(equipment?.salesAward || '')
  const [salesCardOrdered, setSalesCardOrdered] = useState(equipment?.salesCardOrdered ? new Date(equipment?.salesCardOrdered) : null)
  const [salesEmail, setSalesEmail] = useState(equipment?.salesEmail || '')
  const [salesFirstName, setSalesFirstName] = useState(equipment?.salesFirstName || '')
  const [salesLastName, setSalesLastName] = useState(equipment?.salesLastName || '')
  const [salesMoneyLoaded, setSalesMoneyLoaded] = useState(equipment?.salesMoneyLoaded ? new Date(equipment?.salesMoneyLoaded) : null)
  const [serialNumber, setSerialNumber] = useState(equipment?.serialNumber || '')
  const [serialNumberOld, setSerialNumberOld] = useState(equipment?.serialNumberOld || '')
  const [shippingDate, setShippingDate] = useState(equipment?.shippingDate ? new Date(equipment?.shippingDate) : null)
  const [rmaNumber, setRmaNumber] = useState(equipment?.rmaNumber || '')
  const [rmaIssueDate, setRmaIssueDate] = useState(equipment?.rmaIssueDate ? new Date(equipment?.rmaIssueDate) : null)
  const [rmaReturnedDate, setRmaReturnedDate] = useState(equipment?.rmaReturnedDate ? new Date(equipment?.rmaReturnedDate) : null)
  const [unitRef, setUnitRef] = useState(equipment?.unit)
  const [unitModel] = useState(equipment?.unitModel)

  let distributorOptions
  let defaultDistributor

  if (!(isFetchingDistributors || isLoadingDistributors)) {
    distributorOptions = []
    defaultDistributor = {}
  }
  if (isSuccessDistributors) {
    distributorOptions = distributors
      .filter((distributor) => distributor.isActive)
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((distributor) => {
        return { value: distributor, label: distributor.name, customLabel: `${distributor.address} ${distributor.city}, ${distributor.state} ${distributor.zip} ${distributor?.isLicensee ? ' - [LICENSEE]' : ''}` }
      })

    defaultDistributor = distributors.find((distributor) => distributor?._id === equipment?.distributor)
  }

  let customerOptions
  let defaultCustomer
  if (isLoadingCustomers) {
    customerOptions = []
    defaultCustomer = {}
  }
  if (isSuccessCustomers) {
    customerOptions = customers
      ?.filter((customer) => customer.isActive)
      ?.sort((a, b) => a.name.localeCompare(b.name))
      ?.map((customer) => {
        return { value: customer, label: customer.name, customLabel: `${customer.address} ${customer.city}, ${customer.state} ${customer.zip}` }
      })
    defaultCustomer = customers?.find((customer) => customer?._id === equipment?.customer)
  }

  let unitOptions
  let defaultUnit
  if (isLoadingUnits) {
    unitOptions = []
    defaultUnit = {}
  }
  if (isSuccessUnits) {
    unitOptions = units
      ?.filter((unit) => unit.isActive)
      ?.sort((a, b) => a.model.localeCompare(b.model))
      ?.map((unit) => {
        return { value: unit, label: unit.model }
      })
    defaultUnit = units?.find((unit) => unit?._id === equipment?.unit)
  }

  const [getExpirationDate] = useLazyGetExpirationDateQuery()

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsPending(true)

    const equipmentData = {
      id: equipment._id,
      expirationDate: expirationDate ? new Date(expirationDate) : null,
      awardTotal: +salesAward + +registrantAward || 0,
      compressorHorsepower,
      customer: customerRef ? customerRef?._id : null,
      customerName: customerRef ? customerRef?.name : null,
      customerType,
      distributor: distributorRef?._id,
      distributorName: distributorRef ? distributorRef?.name : null,
      installationDate: installationDate ? new Date(installationDate) : null,
      isPreRegistered,
      isRecycled,
      isRegistered,
      preRegistrantAward: +preRegistrantAward || 0,
      preRegistrantCardOrdered: preRegistrantCardOrdered ? new Date(preRegistrantCardOrdered) : null,
      preRegistrantEmail: preRegistrantEmail || '',
      preRegistrantFirstName: preRegistrantFirstName || '',
      preRegistrantLastName: preRegistrantLastName || '',
      preRegistrantMoneyLoaded: preRegistrantMoneyLoaded ? new Date(preRegistrantMoneyLoaded) : null,
      preRegistrationDate: preRegistrationDate ? new Date(preRegistrationDate) : null,
      purchaseOrder,
      registrantAward: +registrantAward || 0,
      registrantCardOrdered: registrantCardOrdered ? new Date(registrantCardOrdered) : null,
      registrantEmail: registrantEmail || '',
      registrantFirstName: registrantFirstName || '',
      registrantLastName: registrantLastName || '',
      registrantMoneyLoaded: registrantMoneyLoaded ? new Date(registrantMoneyLoaded) : null,
      registrationDate: registrationDate ? new Date(registrationDate) : null,
      rmaIssueDate: rmaIssueDate ? new Date(rmaIssueDate) : null,
      rmaNumber: rmaNumber || '',
      rmaReturnedDate: rmaReturnedDate ? new Date(rmaReturnedDate) : null,
      salesAward: +salesAward || 0,
      salesCardOrdered: salesCardOrdered ? new Date(salesCardOrdered) : null,
      salesEmail: salesEmail || '',
      salesFirstName: salesFirstName || '',
      salesLastName: salesLastName || '',
      salesMoneyLoaded: salesMoneyLoaded ? new Date(salesMoneyLoaded) : null,
      serialNumber: serialNumber ? serialNumber : '',
      serialNumberOld: serialNumberOld ? serialNumberOld : '',
      shippingDate: shippingDate ? new Date(shippingDate) : null,
      unit: unitRef ? unitRef?._id : null,
      unitModel: unitRef ? unitRef?.model : null,
      updatedUser: equipment.userName
    }

    const { data: calcExpirationDate } = await getExpirationDate({ unitModel, compressorHorsepower, installationDate })
    setExpirationDate(calcExpirationDate)
    equipmentData.expirationDate = new Date(calcExpirationDate)

    try {
      await updateEquipment(equipmentData).unwrap()
      toast.info(
        <>
          Equipment <strong>#{equipment.serialNumber}</strong> has been updated.
        </>
      )
    } catch (error) {
      toast.error(
        <>
          Equipment <strong>#{equipment.serialNumber}</strong> was not updated.
        </>
      )
    }
    handleCloseModal('equipmentModal')
    setIsPending(false)
  }

  const handleRegistrationDateChange = (e) => {
    if (e === null) {
      setIsRegistered(false)
    } else {
      setIsRegistered(true)
    }
    setRegistrationDate(e)
  }

  const handlePreRegistrationDateChange = (e) => {
    if (e === null) {
      setIsPreRegistered(false)
    } else {
      setIsPreRegistered(true)
    }
    setPreRegistrationDate(e)
  }

  const handleDistributorChange = (e) => {
    if (e === null) {
      setDistributorRef(null)
    } else {
      setDistributorRef(e.value)
    }
  }
  const handleCustomerChange = (e) => {
    if (e === null) {
      setCustomerRef(null)
    } else {
      setCustomerRef(e.value)
    }
  }
  const handleUnitChange = (e) => {
    if (e === null) {
      setUnitRef(null)
    } else {
      setUnitRef(e.value)
    }
  }
  const handleClearCustomerType = (e) => {
    e.preventDefault()
    setCustomerType('')
  }

  const handleClose = (e, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return
    }
    handleCloseModal('equipmentModal')
  }

  return (
    <Modal
      open={openModals.equipmentModal}
      onClose={handleClose}
      closeAfterTransition>
      <Fade in={openModals.equipmentModal}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', maxWidth: 1300, width: '100%', backgroundColor: theme.vars.palette.customBackground[theme.palette.mode], boxShadow: 24 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            p={1}>
            <Typography sx={{ lineHeight: 1, mr: 5, textWrap: 'nowrap', fontSize: '1.5rem', fontWeight: '500' }}>Update Equipment</Typography>
            <Box>
              <CustomCheckBox
                checked={!!isPreRegistered}
                onChange={(e) => setIsPreRegistered(e.target.checked)}
                label="Pre-Registered?"
              />
              <CustomCheckBox
                checked={!!isRegistered}
                onChange={(e) => setIsRegistered(e.target.checked)}
                label="Registered?"
              />
              <CustomCheckBox
                checked={!!isRecycled}
                onChange={(e) => setIsRecycled(e.target.checked)}
                label="Recycled?"
              />
              <IconButton
                aria-label="Close"
                onClick={handleClose}
                sx={{ color: theme.vars.palette.black.main, [theme.getColorSchemeSelector('dark')]: { color: '#fff' } }}>
                <CloseIcon fontSize="20px" />
              </IconButton>
            </Box>
          </Stack>
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', gap: 1, px: 1, py: 0.75 }}>
              <Box>
                <CustomTextInput
                  isInModal={true}
                  value={purchaseOrder}
                  onChange={setPurchaseOrder}
                  label="Purchase Order #"
                  name="purchaseOrder#"
                />
              </Box>
              <Box>
                <CustomDatePicker
                  isInModal={true}
                  label="Installation Date"
                  value={installationDate}
                  onChange={setInstallationDate}
                />
              </Box>
              <Box>
                <CustomDatePicker
                  isInModal={true}
                  label="Expiration Date"
                  value={expirationDate}
                  onChange={setExpirationDate}
                  disabled
                />
              </Box>
              <Box>
                <CustomDatePicker
                  isInModal={true}
                  label="Registration Date"
                  value={registrationDate}
                  onChange={handleRegistrationDateChange}
                />
              </Box>
              <Box>
                <CustomDatePicker
                  isInModal={true}
                  label="Pre-Registration Date"
                  value={preRegistrationDate}
                  onChange={handlePreRegistrationDateChange}
                />
              </Box>
            </Box>
            <Box
              display="grid"
              gridTemplateColumns="repeat(2, 1fr)">
              <Box sx={{ backgroundColor: theme.vars.palette.maroon.dark, color: '#fff', px: 1, py: 0.25 }}>
                <Typography
                  component="h6"
                  sx={{ fontWeight: '500' }}>
                  Distributor
                </Typography>
              </Box>
              <Box sx={{ backgroundColor: theme.vars.palette.seafoam.dark, color: '#fff', px: 1, py: 0.25 }}>
                <Typography
                  component="h6"
                  sx={{ fontWeight: '500' }}>
                  Customer
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 1, px: 1, py: 0.75 }}>
              <CustomWindowedSelect
                isInModal={true}
                options={distributorOptions}
                onChange={handleDistributorChange}
                defaultValue={
                  defaultDistributor ? { value: defaultDistributor, label: defaultDistributor?.name, customLabel: `${defaultDistributor?.address} ${defaultDistributor?.city}, ${defaultDistributor?.state} ${defaultDistributor?.zip}` } : null
                }
              />
              <CustomWindowedSelect
                isInModal={true}
                options={customerOptions}
                onChange={handleCustomerChange}
                defaultValue={defaultCustomer ? { value: defaultCustomer, label: defaultCustomer.name, customLabel: `${defaultCustomer?.address} ${defaultCustomer?.city}, ${defaultCustomer?.state} ${defaultCustomer?.zip}` } : null}
              />
            </Box>
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr', backgroundColor: theme.vars.palette.slate.dark, color: '#fff', px: 1, py: 0.25, mb: 0.75 }}>
              <Typography
                component="h6"
                sx={{ fontWeight: '500' }}>
                Equipment
              </Typography>
            </Box>
            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', gap: 1, px: 1, mb: 0.75 }}>
              <CustomWindowedSelect
                isInModal={true}
                options={unitOptions}
                onChange={handleUnitChange}
                defaultValue={defaultUnit ? { value: defaultUnit, label: defaultUnit.model } : null}
                titleLabel="Model"
              />
              <CustomTextInput
                isInModal={true}
                value={compressorHorsepower}
                onChange={setCompressorHorsepower}
                label="Horsepower"
                name="horsepower"
              />
              <CustomTextInput
                isInModal={true}
                value={serialNumber}
                onChange={setSerialNumber}
                label="New Serial #"
                name="newSerial#"
              />
              <CustomTextInput
                isInModal={true}
                value={serialNumberOld}
                onChange={setSerialNumberOld}
                label="Old Serial #"
                name="oldSerial#"
                extraLabel="if applicable"
              />
              <CustomDatePicker
                isInModal={true}
                label="Shipping Date"
                value={shippingDate}
                onChange={setShippingDate}
              />
            </Box>
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr', px: 1.5, mb: 0.75 }}>
              <Box>
                <CustomRadioButton
                  label="Replaced Competitor OWS"
                  value="Replaced Competitor OWS"
                  checked={customerType === 'Replaced Competitor OWS'}
                  onChange={() => setCustomerType('Replaced Competitor OWS')}
                  name="customerType"
                />
                <CustomRadioButton
                  label="CRP Replacement"
                  value="CRP Replacement"
                  checked={customerType === 'CRP Replacement'}
                  onChange={() => setCustomerType('CRP Replacement')}
                  name="customerType"
                />
                <CustomRadioButton
                  label="First-Time OWS Customer"
                  value="First-Time OWS Customer"
                  checked={customerType === 'First-Time OWS Customer'}
                  onChange={() => setCustomerType('First-Time OWS Customer')}
                  name="customerType"
                />
                <CustomRadioButton
                  label="Don't know"
                  value="Don't know"
                  checked={customerType === "Don't know"}
                  onChange={() => setCustomerType("Don't know")}
                  name="customerType"
                />
                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    '&:hover': {
                      color: '#fff',
                      backgroundColor: theme.vars.palette.slate.dark,
                      borderColor: theme.vars.palette.slate.darker
                    },
                    [theme.getColorSchemeSelector('dark')]: {
                      color: '#fff',
                      borderColor: '#fff',
                      '&:hover': {
                        backgroundColor: theme.vars.palette.slate.main,
                        borderColor: theme.vars.palette.slate.dark
                      }
                    },
                    py: 0.125,
                    px: 0.75
                  }}
                  onClick={handleClearCustomerType}>
                  Clear selection
                </Button>
              </Box>
            </Box>
            {pageTitle === 'recycled' && (
              <>
                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr', backgroundColor: theme.vars.palette.black.dark, color: '#fff', px: 1, py: 0.25, mb: 0.75 }}>
                  <Typography
                    component="h6"
                    sx={{ fontWeight: '500' }}>
                    RMA
                  </Typography>
                </Box>
                <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 1, px: 1, mb: 0.75 }}>
                  <CustomTextInput
                    isInModal={true}
                    value={rmaNumber}
                    onChange={setRmaNumber}
                    label="RMA Number"
                    name="rmaNumber"
                  />
                  <CustomDatePicker
                    isInModal={true}
                    label="Issue Date"
                    value={rmaIssueDate}
                    onChange={setRmaIssueDate}
                  />
                  <CustomDatePicker
                    isInModal={true}
                    label="Returned Date"
                    value={rmaReturnedDate}
                    onChange={setRmaReturnedDate}
                  />
                </Box>
              </>
            )}
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr', backgroundColor: theme.vars.palette.success.main, color: '#fff', px: 1, py: 0.25, mb: 0.75 }}>
              <Typography
                component="h6"
                sx={{ fontWeight: '500' }}>
                Pre-Registration
              </Typography>
            </Box>
            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(6, 1fr)', gap: 1, alignItems: 'end', px: 1, mb: 0.75 }}>
              <Box>
                <Typography
                  component="p"
                  fontWeight="bold">
                  Registrant
                </Typography>
                <CustomTextInput
                  isInModal={true}
                  value={preRegistrantFirstName}
                  onChange={setPreRegistrantFirstName}
                  label="First Name"
                  name="preregistrantFirstName"
                />
              </Box>
              <Box>
                <CustomTextInput
                  isInModal={true}
                  value={preRegistrantLastName}
                  onChange={setPreRegistrantLastName}
                  label="Last Name"
                  name="preregistrantLastName"
                />
              </Box>
              <Box>
                <CustomTextInput
                  isInModal={true}
                  value={preRegistrantEmail}
                  onChange={setPreRegistrantEmail}
                  label="Email"
                  name="preregistrantEmail"
                  type="email"
                />
              </Box>
              <Box>
                <Typography
                  component="p"
                  fontWeight="bold">
                  Registrant
                </Typography>
                <CustomDatePicker
                  isInModal={true}
                  label="Card Ordered"
                  value={preRegistrantCardOrdered}
                  onChange={setPreRegistrantCardOrdered}
                />
              </Box>
              <Box>
                <CustomDatePicker
                  isInModal={true}
                  label="Money Loaded"
                  value={preRegistrantMoneyLoaded}
                  onChange={setPreRegistrantMoneyLoaded}
                />
              </Box>
              <Box>
                <CustomTextInput
                  isInModal={true}
                  value={preRegistrantAward}
                  onChange={setPreRegistrantAward}
                  label="Award Amount"
                  name="registrantAwardAmount"
                  placeholder="$0"
                />
              </Box>
            </Box>
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr', backgroundColor: theme.vars.palette.black.dark, color: '#fff', px: 1, py: 0.25, mb: 0.75 }}>
              <Typography
                component="h6"
                sx={{ fontWeight: '500' }}>
                Registration/Award Recipient
              </Typography>
            </Box>
            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(6, 1fr)', gap: 1, alignItems: 'end', px: 1, mb: 0.75 }}>
              <Box>
                <Typography
                  component="p"
                  fontWeight="bold">
                  Registrant
                </Typography>
                <CustomTextInput
                  isInModal={true}
                  value={registrantFirstName}
                  onChange={setRegistrantFirstName}
                  label="First Name"
                  name="rgistrantFirstName"
                />
              </Box>
              <Box>
                <CustomTextInput
                  isInModal={true}
                  value={registrantLastName}
                  onChange={setRegistrantLastName}
                  label="Last Name"
                  name="registrantLastName"
                />
              </Box>
              <Box>
                <CustomTextInput
                  isInModal={true}
                  value={registrantEmail}
                  onChange={setRegistrantEmail}
                  label="Email"
                  name="registrantEmail"
                  type="email"
                />
              </Box>
              <Box>
                <Typography
                  component="p"
                  fontWeight="bold">
                  Sales or Service Personnel
                </Typography>
                <CustomTextInput
                  isInModal={true}
                  value={salesFirstName}
                  onChange={setSalesFirstName}
                  label="First Name"
                  name="salesFirstName"
                />
              </Box>
              <Box>
                <CustomTextInput
                  isInModal={true}
                  value={salesLastName}
                  onChange={setSalesLastName}
                  label="Last Name"
                  name="salesLastName"
                />
              </Box>
              <Box>
                <CustomTextInput
                  isInModal={true}
                  value={salesEmail}
                  onChange={setSalesEmail}
                  label="Email"
                  name="salesEmail"
                  type="email"
                />
              </Box>
            </Box>
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr', backgroundColor: theme.vars.palette.eggplant.dark, color: '#fff', px: 1, py: 0.25, mb: 0.75 }}>
              <Typography
                component="h6"
                sx={{ fontWeight: '500' }}>
                Administration
              </Typography>
            </Box>
            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(6, 1fr)', gap: 1, alignItems: 'end', px: 1, pb: 0.75 }}>
              <Box>
                <Typography
                  component="p"
                  fontWeight="bold">
                  Registrant
                </Typography>
                <CustomDatePicker
                  isInModal={true}
                  label="Card Ordered"
                  value={registrantCardOrdered}
                  onChange={setRegistrantCardOrdered}
                />
              </Box>
              <Box>
                <CustomDatePicker
                  isInModal={true}
                  label="Money Loaded"
                  value={registrantMoneyLoaded}
                  onChange={setRegistrantMoneyLoaded}
                />
              </Box>
              <Box>
                <CustomTextInput
                  isInModal={true}
                  value={registrantAward}
                  onChange={setRegistrantAward}
                  label="Award Amount"
                  name="registrantAwardAmount"
                  placeholder="$0"
                />
              </Box>
              <Box>
                <Typography
                  component="p"
                  fontWeight="bold">
                  Sales or Service Personnel
                </Typography>
                <CustomDatePicker
                  isInModal={true}
                  label="Card Ordered"
                  value={salesCardOrdered}
                  onChange={setSalesCardOrdered}
                />
              </Box>
              <Box>
                <CustomDatePicker
                  isInModal={true}
                  label="Money Loaded"
                  value={salesMoneyLoaded}
                  onChange={setSalesMoneyLoaded}
                />
              </Box>
              <Box>
                <CustomTextInput
                  isInModal={true}
                  value={salesAward}
                  onChange={setSalesAward}
                  label="Award Amount"
                  name="salesAwardAmount"
                  placeholder="$0"
                />
              </Box>
            </Box>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
              p={1}>
              <Typography sx={{ fontSize: '12px' }}>
                Created {equipment?.createdUser && <>by {equipment?.createdUser}</>} on {dayjs(equipment?.createdAt).format('MM/DD/YYYY hh:mm:ss a')}
                <br />
                Last updated {equipment?.updatedUser && <>by {equipment?.updatedUser}</>} on {dayjs(equipment?.updatedAt).format('MM/DD/YYYY hh:mm:ss a')}
              </Typography>
              <Stack
                direction="row"
                spacing={1}>
                <Button
                  variant="contained"
                  size="small"
                  color="cancel"
                  type="button"
                  onClick={handleClose}>
                  Cancel
                </Button>
                {!pending && (
                  <Button
                    variant="contained"
                    size="small"
                    type="submit">
                    Save Changes
                  </Button>
                )}
                {pending && (
                  <Button
                    variant="contained"
                    size="small"
                    type="button"
                    disabled>
                    <CircularProgress
                      sx={{ color: theme.vars.palette.cancel.light, mr: 0.5 }}
                      size={18}
                    />
                    Saving...
                  </Button>
                )}
              </Stack>
            </Stack>
          </form>
        </Box>
      </Fade>
    </Modal>
  )
}

export default UpdateEquipmentForm
