import { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import CircularProgress from '@mui/material/CircularProgress'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Unstable_Grid2'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useUpdateUserMutation } from '../users/usersApiSlice'
import { useModalContext } from '../../hooks/useModalContext'
import { useUserContext } from '../../hooks/useUserContext'
import { toast } from 'react-toastify'
import { ROLES } from '../../config/roles'
import CustomTextInput from '../../components/CustomTextInput'
import CustomWindowedSelect from '../../components/CustomWindowedSelect'
import useAuth from '../../hooks/useAuth'
import { useTheme } from '@mui/material'

const UpdateUserForm = () => {
  const theme = useTheme()
  const { name: authUserName, role: authUserRole } = useAuth()
  const { setUserInfo } = useUserContext()
  const [updateUser] = useUpdateUserMutation()
  const { handleCloseModal, selectedRow: user } = useModalContext()
  const [name, setName] = useState(user?.name)
  const [email, setEmail] = useState(user?.email)
  const [role, setRole] = useState(user?.role)
  const [isActive, setIsActive] = useState(user?.isActive)
  const [isPending, setIsPending] = useState(false)

  const roleOptions = Object.values(ROLES)
    .filter((role) => role !== 'externaluser')
    .map((role) => {
      return { value: role, label: role.charAt(0).toUpperCase() + role.slice(1) }
    })

  const handleUpdate = async (e) => {
    e.preventDefault()
    setIsPending(true)

    const userData = {
      id: user._id,
      name,
      email,
      role,
      isActive
    }

    try {
      await updateUser(userData).unwrap()
      if (name === authUserName) {
        setUserInfo(userData)
      }
      toast.info(
        <>
          <Typography
            component="h5"
            fontWeight="bold">
            Updated!
          </Typography>
          <strong>{user.name}</strong> has been updated.
        </>
      )
    } catch (error) {
      toast.error(
        <>
          <Typography
            component="h5"
            fontWeight="bold">
            Error!
          </Typography>
          <strong>{user.name}</strong> has not been updated.
        </>
      )
    }
    handleCloseModal('updateModal')
  }

  const handleRoleChange = (e) => {
    if (e === null) {
      setRole(null)
    } else {
      setRole(e.value)
    }
  }

  const created = user.createdAt ? new Date(user.createdAt).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }) : 'N/A'
  const updated = user.updatedAt ? new Date(user.updatedAt).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }) : 'N/A'

  return (
    <form>
      <Box sx={{ px: 2, py: 1 }}>
        <Grid
          container
          columnSpacing={2}>
          <Grid
            sm={6}
            sx={{ mb: 1 }}>
            <CustomTextInput
              isInModal={true}
              value={name}
              onChange={setName}
              label="Name"
              name="name"
            />
          </Grid>
          <Grid
            sm={6}
            sx={{ mb: 1 }}>
            <CustomTextInput
              isInModal={true}
              value={email}
              onChange={setEmail}
              label="Email"
              name="email"
              type="email"
              disabled={authUserRole !== 'superuser'}
            />
          </Grid>
          <Grid
            sm={12}
            sx={{ mb: 1 }}>
            <CustomWindowedSelect
              isInModal={true}
              options={roleOptions}
              onChange={handleRoleChange}
              defaultValue={roleOptions.find((role) => role.value === user.role)}
              titleLabel="Role"
            />
          </Grid>
        </Grid>
      </Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
        sx={{ px: 2, pb: 2 }}>
        <Typography style={{ fontSize: '13px' }}>
          <strong>Date Created:</strong> {created}
          <br />
          <strong>Date Updated:</strong> {updated}
        </Typography>
        <FormControlLabel
          sx={{ fontSize: '14px' }}
          control={
            <Checkbox
              size="small"
              color="black"
              sx={{ py: 0 }}
            />
          }
          label="Active"
          labelPlacement="end"
          id="isLicenseeUpdate"
          onChange={(e) => setIsActive(e.target.checked)}
          checked={!!isActive}
        />
        <Stack
          direction="row"
          spacing={1}
          justifyContent="flex-start">
          <Button
            variant="contained"
            size="small"
            color="cancel"
            onClick={() => handleCloseModal('updateModal')}
            type="button">
            Cancel
          </Button>
          {!isPending && (
            <Button
              variant="contained"
              size="small"
              color="black"
              onClick={handleUpdate}>
              Save Changes
            </Button>
          )}
          {isPending && (
            <Button
              variant="contained"
              size="small"
              type="button"
              disabled>
              <CircularProgress
                sx={{ color: theme.vars.palette.cancel.light, mr: 0.5 }}
                size={18}
              />
              Saving...
            </Button>
          )}
        </Stack>
      </Stack>
    </form>
  )
}
export default UpdateUserForm
