import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Unstable_Grid2'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { useModalContext } from '../hooks/useModalContext'
import useAuth from '../hooks/useAuth'
import { useDebounce } from 'use-debounce'

const CustomTableFooter = ({ data, handleLimitChange, limit, page, handleSetPage, buttonColor }) => {
  const { role } = useAuth()
  const { hasPrevPage, hasNextPage, prevPage, nextPage, totalDocs, totalPages } = data
  const { handleOpenModal, pageTitle } = useModalContext()
  const [userInput, setUserInput] = useState(page)
  const [debouncedInput] = useDebounce(userInput, 350)

  const handleButtonClick = () => {
    handleOpenModal('createModal')
  }

  const handlePageChange = (newPage) => {
    setUserInput(newPage)
  }

  useEffect(() => {
    handleSetPage(debouncedInput)
  }, [handleSetPage, debouncedInput])

  return (
    <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 3 }}>
      <Grid xs>
        <Stack
          direction="row"
          alignItems="center">
          {pageTitle && (role === 'superuser' || (role === 'admin' && (pageTitle === 'equipment' || pageTitle === 'customer'))) && !(pageTitle === 'recycled' || pageTitle === 'log') && (
            <Button
              color={buttonColor ?? 'primary'}
              size="small"
              onClick={handleButtonClick}
              variant="contained"
              type="button"
              sx={{ mr: 1 }}>
              Add New
            </Button>
          )}
          <Typography sx={{ fontSize: '.875rem' }}>
            <strong>{totalDocs}</strong>&nbsp;
            <Box
              component="span"
              sx={{ textTransform: 'capitalize' }}>
              {pageTitle === 'externaluser' ? 'DB Access' : pageTitle}
            </Box>
            {!(pageTitle === 'recycled' || pageTitle === 'externaluser') && 's'}
          </Typography>
        </Stack>
      </Grid>

      <Grid
        xs
        display="flex"
        justifyContent="end">
        {totalDocs > limit && (
          <Stack
            spacing={1}
            direction="row"
            alignItems="center"
            divider={
              <Divider
                orientation="vertical"
                flexItem
              />
            }>
            <Stack
              direction="row"
              sx={{ alignItems: 'center', justifyContent: 'flex-end' }}>
              <Tooltip title="First page">
                <span>
                  <IconButton
                    size="small"
                    onClick={() => handlePageChange(1)}
                    disabled={!hasPrevPage}>
                    <FirstPageIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title="Prev page">
                <span>
                  <IconButton
                    size="small"
                    onClick={() => handlePageChange(prevPage)}
                    disabled={!hasPrevPage}>
                    <KeyboardArrowLeftIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title="Next page">
                <span>
                  <IconButton
                    size="small"
                    onClick={() => handlePageChange(nextPage)}
                    disabled={!hasNextPage}>
                    <KeyboardArrowRightIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title="Last page">
                <span>
                  <IconButton
                    size="small"
                    onClick={() => handlePageChange(totalPages)}
                    disabled={!hasNextPage}>
                    <LastPageIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
            <Typography
              variant="body2"
              sx={{ flexShrink: 1 }}>
              Page{' '}
              <strong>
                {page} of {totalPages}
              </strong>
            </Typography>
            <span>
              <Typography
                variant="body2"
                sx={{ mb: 0, display: 'inline-block' }}>
                Go to page:
              </Typography>
              <FormControl>
                <TextField
                  sx={{ ml: 1, display: 'inline-block', width: 50, fontSize: '.875rem' }}
                  value={userInput}
                  size="small"
                  onChange={(e) => handlePageChange(e.target.value ? Number(e.target.value) : 0)}
                  min="1"
                  max={totalPages}
                  type="number"
                  variant="standard"
                />
              </FormControl>
            </span>
            <Typography variant="body2">Rows per page:</Typography>
            <FormControl
              variant="standard"
              sx={{ width: 50 }}>
              <Select
                aria-label="page size"
                size="small"
                sx={{ fontSize: '.875rem' }}
                variant="standard"
                value={limit}
                onChange={handleLimitChange}>
                {[10, 15, 20, 25, 30, 35, 40, 45, 50].map((pageSize) => (
                  <MenuItem
                    key={pageSize}
                    value={pageSize}>
                    {pageSize}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        )}
      </Grid>
    </Grid>
  )
}

export default CustomTableFooter
