import { memo, useCallback, useMemo, useState } from 'react'
import { useGetPaginatedExtUsersQuery } from './extUsersApiSlice'
import useTitle from '../../hooks/useTitle'
import BeatLoader from 'react-spinners/BeatLoader'
import CustomTableFooter from '../../components/CustomTableFooter'
import { useModalContext } from '../../hooks/useModalContext'
import { useSearchContext } from '../../hooks/useSearchContext'
import Tooltip from '@mui/material/Tooltip'
import usePageTitle from '../../hooks/usePageTitle'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CircleIcon from '@mui/icons-material/Circle'
import Typography from '@mui/material/Typography'
import { BsTrash } from 'react-icons/bs'
import { BsPencil } from 'react-icons/bs'
import { MdLockReset } from 'react-icons/md'
import { BsInfoCircle } from 'react-icons/bs'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import CustomTable from '../../components/CustomTable'
import CustomActionButtonGroup from '../../components/CustomActionButtonGroup'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)

const ExtUsersList = memo(({ page, handleSetPage }) => {
  const theme = useTheme()
  useTitle('DB Access | BILL Dashboard')
  usePageTitle('externaluser')

  const { handleOpenModal, setSelectedRow } = useModalContext()
  const { searchTerm } = useSearchContext()

  const [sorting, setSorting] = useState([{ id: 'name', desc: false }])
  const [limit, setLimit] = useState(20)

  const headerColor = theme.palette.mode === 'dark' ? 'cancel' : 'black'
  const buttonColor = theme.palette.mode === 'dark' ? 'cancel' : 'black'
  const beatLoaderColor = theme.palette.mode === 'dark' ? theme.vars.palette.cancel.main : theme.vars.palette.black.main

  const handleEditClick = useCallback(
    (selected) => {
      handleOpenModal('updateModal')
      setSelectedRow(selected)
    },
    [handleOpenModal, setSelectedRow]
  )

  const handleDeleteClick = useCallback(
    (selected) => {
      handleOpenModal('deleteModal')
      setSelectedRow(selected)
    },
    [handleOpenModal, setSelectedRow]
  )

  const handleResetClick = useCallback(
    (selected) => {
      handleOpenModal('passwordModal')
      setSelectedRow(selected)
    },
    [handleOpenModal, setSelectedRow]
  )

  const handleLimitChange = useCallback(
    (e) => {
      handleSetPage(1)
      setLimit(+e.target.value)
    },
    [handleSetPage, setLimit]
  )

  const handleSetSorting = useCallback(
    (newSorting) => {
      handleSetPage(1)
      setSorting(newSorting)
    },
    [handleSetPage, setSorting]
  )

  const {
    data: extUsers,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetPaginatedExtUsersQuery({ page, limit, sorting, searchTerm }, 'extUsersList', {
    pollingInterval: 15000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true
  })

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        id: 'name',
        header: 'Name',
        Cell: ({ row }) => {
          const { name, isOnline } = row.original ?? {}
          return (
            <>
              <Box sx={{ position: 'relative' }}>
                {name ?? ''}
                {isOnline && <CircleIcon sx={{ position: 'absolute', color: 'success.main', width: '0.75rem', top: '-9px', marginLeft: '2px' }} />}
              </Box>
            </>
          )
        }
      },
      {
        accessorKey: 'email',
        id: 'email',
        header: 'Email'
      },
      {
        accessorKey: 'lastVisited',
        id: 'lastVisted',
        header: 'Last Visited',
        Cell: ({ row }) => (row?.original?.lastVisited ? dayjs(row?.original?.lastVisited).format('MM/DD/YYYY hh:mm:ss A z') : '')
      },
      {
        accessorKey: 'role',
        id: 'role',
        header: 'Role',
        Cell: () => <div>External User</div>
      },
      {
        accessorKey: 'distributors',
        id: 'distributors',
        header: 'Affiliation',
        size: 100,
        maxSize: 100,
        minSize: 75,
        Cell: ({ row }) => {
          const distributors = row.original.distributors
          const distributorsList = distributors.map((d) => d.name).join(', ')
          const truncatedList = distributorsList.length > 50 ? distributorsList.substring(0, 44) + '...' : distributorsList
          return (
            <div style={{ position: 'relative' }}>
              <Typography
                component="span"
                sx={{ fontSize: '.8rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%', display: 'inline-block' }}>
                {truncatedList}
              </Typography>
              {distributorsList.length >= 50 && (
                <Tooltip
                  title={distributorsList}
                  placement="bottom"
                  leaveDelay={0}>
                  <div style={{ position: 'absolute', right: 0, top: 0 }}>
                    <BsInfoCircle style={{ color: 'teal', zIndex: 1000 }} />
                  </div>
                </Tooltip>
              )}
            </div>
          )
        }
      },
      {
        header: 'Active',
        id: 'isActive',
        accessorKey: 'isActive',
        enableSorting: false,
        muiTableHeadCellProps: { align: 'center' },
        size: 50,
        Cell: ({ row }) => (
          <Checkbox
            checked={!!row.getValue('isActive')}
            readOnly
            disabled
            icon={<CheckBoxOutlineBlankIcon sx={{ fontSize: 15, color: 'lightgray' }} />}
            checkedIcon={
              <CheckBoxIcon
                sx={{ fontSize: 15 }}
                color="cancel"
              />
            }
            sx={{
              fontSize: 20,
              display: 'flex',
              alignItems: 'center',
              p: 0
            }}
          />
        )
      },
      {
        header: 'Actions',
        id: 'actions',
        size: 50,
        muiTableHeadCellProps: { align: 'right' },
        muiTableBodyCellProps: { sx: { p: 0, m: 0 } },
        Cell: ({ row }) => (
          <CustomActionButtonGroup
            row={row}
            buttons={[
              {
                title: 'Edit',
                color: 'primary',
                onClick: handleEditClick,
                icon: <BsPencil />,
                visible: true
              },
              {
                title: 'Delete',
                color: 'error',
                onClick: handleDeleteClick,
                icon: <BsTrash />,
                visible: true
              },
              {
                title: 'Reset PW',
                color: 'info',
                onClick: handleResetClick,
                icon: <MdLockReset />,
                visible: true
              }
            ]}
          />
        )
      }
    ],
    [handleEditClick, handleDeleteClick, handleResetClick]
  )

  if (isLoading)
    return (
      <BeatLoader
        color={beatLoaderColor}
        size={20}
      />
    )

  if (isError)
    return (
      <>
        <p className="errmsg">{error?.data?.message}</p>
        <Button
          color="black"
          size="small"
          onClick={() => handleOpenModal('createModal')}
          variant="contained"
          type="button">
          Add New
        </Button>
      </>
    )

  if (isSuccess) {
    const { docs } = extUsers

    return (
      <>
        <CustomTable
          columns={columns}
          docs={docs}
          isError={isError}
          sorting={sorting}
          handleSetSorting={handleSetSorting}
          color={headerColor}
        />
        <CustomTableFooter
          page={page}
          handleSetPage={handleSetPage}
          data={extUsers}
          limit={limit}
          handleLimitChange={handleLimitChange}
          buttonColor={buttonColor}
        />
      </>
    )
  }
})

export default ExtUsersList
