export const formatPhoneNumberForDisplay = (phoneNumber) => {
  if (!phoneNumber) return ''

  const cleaned = phoneNumber.replace(/\D/g, '')

  const isCountryCode = cleaned.startsWith('1') && cleaned.length === 11

  const cleanedWithoutCountryCode = isCountryCode ? cleaned.slice(1) : cleaned

  if (cleanedWithoutCountryCode.length === 7 || cleanedWithoutCountryCode.length < 10) {
    return cleanedWithoutCountryCode
  }
  return `(${cleanedWithoutCountryCode.slice(0, 3)}) ${cleanedWithoutCountryCode.slice(3, 6)}-${cleanedWithoutCountryCode.slice(6, 10)}`
}

export const formatPhoneNumberForSaving = (phoneNumber) => {
  if (!phoneNumber) return ''

  const cleaned = phoneNumber.replace(/\D/g, '')

  if (cleaned.length === 11 && cleaned.startsWith('1')) {
    return `+${cleaned}`
  }

  if (cleaned.length === 10) {
    return `+1${cleaned}`
  }

  return cleaned
}
