import { createSelector, createEntityAdapter } from '@reduxjs/toolkit'
import { apiSlice } from '../../app/api/apiSlice'

const distributorsAdapter = createEntityAdapter({})

const initialState = distributorsAdapter.getInitialState()

export const distributorsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDistributor: builder.query({
      query: ({ id }) => ({
        url: `/distributors/${id}`,
        method: 'GET',
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError
        }
      }),
      providesTags: (result, error, id) => [{ type: 'Distributor', id }]
    }),
    getDistributors: builder.query({
      query: () => ({
        url: '/distributors',
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError
        }
      }),
      providesTags: (result) => {
        if (result?.ids) {
          return [{ type: 'Distributor', id: 'LIST' }, ...result.ids.map((id) => ({ type: 'Distributor', id }))]
        } else return [{ type: 'Distributor', id: 'LIST' }]
      }
    }),
    getPaginatedDistributors: builder.query({
      query: (params) => {
        const { page = 1, limit = 20, sorting = [], searchTerm = '' } = params
        return {
          url: '/distributors',
          params: { page, limit, columnSort: sorting.length > 0 ? sorting[0].id : '', columnType: sorting.length > 0 ? sorting[0].desc : '', searchTerm },
          validateStatus: (response, result) => {
            return response.status === 200 && !result.isError
          }
        }
      },
      providesTags: (result) => {
        if (result?.ids) {
          return [{ type: 'Distributor', id: 'LIST' }, ...result.ids.map((id) => ({ type: 'Distributor', id }))]
        } else return [{ type: 'Distributor', id: 'LIST' }]
      }
    }),
    addNewDistributor: builder.mutation({
      query: (initialDistributor) => ({
        url: '/distributors',
        method: 'POST',
        body: {
          ...initialDistributor
        }
      }),
      invalidatesTags: ['Distributor']
    }),
    updateDistributor: builder.mutation({
      query: (initialDistributor) => ({
        url: '/distributors',
        method: 'PATCH',
        body: {
          ...initialDistributor
        }
      }),
      invalidatesTags: ['Distributor']
    }),
    deleteDistributor: builder.mutation({
      query: ({ id }) => ({
        url: '/distributors',
        method: 'DELETE',
        body: { id }
      }),
      invalidatesTags: ['Distributor']
    }),
    getOmittedDistributors: builder.query({
      query: () => ({
        url: '/distributors/omitted',
        method: 'GET'
      })
    })
  })
})

export const {
  useGetDistributorQuery,
  useLazyGetDistributorQuery,
  useGetDistributorsQuery,
  useGetPaginatedDistributorsQuery,
  useAddNewDistributorMutation,
  useUpdateDistributorMutation,
  useDeleteDistributorMutation,
  useLazyGetOmittedDistributorsQuery
} = distributorsApiSlice

// returns the query result object
export const selectDistributorsResults = distributorsApiSlice.endpoints.getDistributors.select()

// creates memoized selector
const selectDistributorsData = createSelector(selectDistributorsResults, (distributorsResult) => distributorsResult.data)

// getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllDistributors,
  selectById: selectDistributorById,
  selectIds: selectDistributorIds
  // Pass in a selector that returns the distributors slice of state
} = distributorsAdapter.getSelectors((state) => selectDistributorsData(state) ?? initialState)
