import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'

const CustomCheckBox = ({ checked, onChange, label }) => {
  return (
    <FormControlLabel
      label={
        <Typography
          component="span"
          sx={{ fontSize: 14 }}>
          {label}
        </Typography>
      }
      control={
        <Checkbox
          checked={!!checked}
          onChange={onChange}
          sx={{
            p: 0.75,
            '& .MuiSvgIcon-root': { fontSize: 18 }
          }}
        />
      }
    />
  )
}

export default CustomCheckBox
