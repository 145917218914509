import { useEffect, useMemo, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { useSendLogoutMutation } from '../features/auth/authApiSlice'
import { useUserContext } from '../hooks/useUserContext'
import useAuth from '../hooks/useAuth'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Brightness4Icon from '@mui/icons-material/Brightness4'
import Brightness7Icon from '@mui/icons-material/Brightness7'
import Button from '@mui/material/Button'
import DashboardIcon from '@mui/icons-material/Dashboard'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { useColorScheme } from '@mui/material/styles'

const DashHeader = () => {
  const { user } = useUserContext()
  const [anchorElNav, setAnchorElNav] = useState(null)
  const { name, role } = useAuth()
  const [sendLogout, { isSuccess }] = useSendLogoutMutation()
  const { logoutUser } = useUserContext()
  const navigate = useNavigate()
  const { mode, setMode } = useColorScheme()
  const defaultPages = [
    { name: 'Equipment', link: './equipments' },
    { name: 'Distributors', link: './distributors' },
    { name: 'Customers', link: './customers' },
    { name: 'Units', link: './units' },
    { name: 'Recycled', link: './recycled' }
  ]

  const additionalPages = useMemo(() => {
    const additional = []
    if (role === 'superuser' || role === 'admin') {
      additional.push({ name: 'Reports', link: './reports' })
    }
    if (role === 'superuser') {
      additional.push({ name: 'Users', link: './users' })
      additional.push({ name: 'DB Access', link: './db-access' })
      additional.push({ name: 'Logs', link: './logs' })
    }
    return additional
  }, [role])

  const pages = role !== 'externaluser' ? [...defaultPages, ...additionalPages] : []

  useEffect(() => {
    if (isSuccess) navigate('/')
  }, [isSuccess, navigate])

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const backgroundColor = useMemo(() => {
    if (process.env.NODE_ENV === 'development') {
      return '#880000'
    } else if (process.env.REACT_APP_ENV === 'stage') {
      return '#1976d2'
    } else {
      return '#212121'
    }
  }, [])

  const navEnvironmentText = useMemo(() => {
    if (process.env.NODE_ENV === 'development') {
      return <Box sx={{ flexGrow: 1, fontWeight: 600 }}>DEVELOPMENT</Box>
    } else if (process.env.REACT_APP_ENV === 'stage') {
      return <Box sx={{ flexGrow: 1, fontWeight: 600 }}>STAGE</Box>
    } else {
      return ''
    }
  }, [])

  const handleUserLogout = async () => {
    try {
      await sendLogout()
      logoutUser()
      navigate('/')
    } catch (error) {
      console.error('Logout failed:', error)
    }
  }

  return (
    <AppBar
      position="static"
      sx={{ py: 0.5, mb: '1rem', backgroundColor }}>
      <Toolbar
        variant="dense"
        disableGutters={true}
        sx={{ px: 1 }}>
        <DashboardIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit">
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: 'block', md: 'none' }
            }}>
            {pages.map((page) => (
              <MenuItem
                component={NavLink}
                to={page.link}
                key={page.name}
                dense={true}
                onClick={handleCloseNavMenu}>
                <Typography textAlign="center">{page.name}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          {pages.map((page) => (
            <Button
              component={NavLink}
              to={page.link}
              key={page.name}
              sx={{
                color: '#ffffff8c',
                display: 'flex',
                textTransform: 'capitalize',
                fontSize: '16px',
                '&:hover': {
                  color: 'white'
                },
                '&.active': {
                  color: 'white'
                }
              }}>
              {page.name}
            </Button>
          ))}
        </Box>
        {navEnvironmentText}
        <Box sx={{ mr: 2 }}>
          <IconButton
            title={`Toggle ${mode} mode`}
            sx={{ ml: 1 }}
            onClick={() => {
              setMode(mode === 'light' ? 'dark' : 'light')
            }}
            color="inherit">
            {mode === 'dark' ? <Brightness7Icon sx={{ fontSize: 24 }} /> : <Brightness4Icon sx={{ fontSize: 24 }} />}
          </IconButton>
        </Box>
        <Box sx={{ flexGrow: 0 }}>
          <Stack
            direction="row"
            spacing={2}
            alignItems="flex-end"
            sx={{ color: '#ffffff8c' }}>
            <Stack
              direction="column"
              spacing={1}>
              <Typography
                variant="caption"
                display="block"
                sx={{ m: 0, lineHeight: 1 }}>
                User: <Box sx={{ color: '#fff', display: 'inline' }}>{user.name || name || 'Unknown'}</Box>
              </Typography>
              <Typography
                variant="caption"
                display="block"
                sx={{ m: 0, lineHeight: 1 }}>
                Role: <Box sx={{ color: '#fff', display: 'inline', textTransform: 'capitalize' }}>{user.role === 'externaluser' ? 'DB Access' : user.role || role || 'Unknown'}</Box>
              </Typography>
            </Stack>
            <Button
              onClick={handleUserLogout}
              variant="outlined"
              color="info"
              size="sm"
              sx={{
                '&:hover': {
                  color: '#fff',
                  backgroundColor: 'info.dark' || 'red' || 'Unknown',
                  borderColor: 'info.dark' || 'darkred' || 'Unknown'
                }
              }}>
              Log out
            </Button>
          </Stack>
        </Box>
      </Toolbar>
    </AppBar>
  )
}
export default DashHeader
