import { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setCredentials } from './authSlice'
import { useLoginMutation } from './authApiSlice'
import usePersist from '../../hooks/usePersist'
import useTitle from '../../hooks/useTitle'
import { Box, Button, Container, TextField, Typography } from '@mui/material'
import Logo from '../../img/crplogo.png'
import jwt_decode from 'jwt-decode'
import { useUserContext } from '../../hooks/useUserContext'
import { useTheme } from '@mui/material'

const Login = () => {
  const theme = useTheme()
  useTitle('Employee Login')
  const { setUserInfo } = useUserContext()
  const emailRef = useRef()
  const errRef = useRef()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [persist, setPersist] = usePersist(true)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [login] = useLoginMutation()

  const d = new Date()

  useEffect(() => {
    emailRef.current.focus()
  }, [])

  useEffect(() => {
    setErrMsg('')
  }, [email, password])

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const { accessToken } = await login({ email, password }).unwrap()
      dispatch(setCredentials({ accessToken }))
      const decodedToken = jwt_decode(accessToken)
      const userRole = decodedToken.UserInfo.role
      setEmail('')
      setPassword('')
      setUserInfo(decodedToken.UserInfo)
      if (userRole === 'admin' || userRole === 'superuser' || userRole === 'user') {
        navigate('/dash/equipments')
      } else {
        navigate('/dash/welcome')
      }
    } catch (error) {
      if (!error.status) {
        setErrMsg('No Server Response')
      } else if (error.status === 400) {
        setErrMsg('Missing Email or Password')
      } else if (error.status === 401) {
        setErrMsg('Unauthorized')
      } else {
        setErrMsg(error.data?.message)
      }
      errRef.current.focus()
    }
  }

  const handleUserInput = (e) => setEmail(e.target.value)
  const handlePwdInput = (e) => setPassword(e.target.value)
  const handleToggle = () => setPersist((prev) => !prev)

  const errClass = errMsg ? 'errmsg' : 'offscreen'

  const content = (
    <Container
      component="main"
      disableGutters={true}
      maxWidth={false}
      sx={{ backgroundColor: '#efefef', [theme.getColorSchemeSelector('dark')]: { backgroundColor: '#1A2027' } }}>
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <Typography
          ref={errRef}
          className={errClass}
          aria-live="assertive"
          color="error">
          {errMsg}
        </Typography>
        <img
          src={Logo}
          alt="Clean Resources Logo"
          style={{ marginBottom: '1rem', maxWidth: '100%', height: 'auto' }}
        />
        <Typography
          component="h1"
          variant="h5">
          Please Login
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 1, maxWidth: '300px' }}>
          <TextField
            margin="dense"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoFocus
            ref={emailRef}
            value={email}
            onChange={handleUserInput}
            variant="outlined"
            autoComplete="on"
            sx={{ backgroundColor: '#fff', [theme.getColorSchemeSelector('dark')]: { backgroundColor: '#1A2027' } }}
          />
          <TextField
            margin="dense"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            onChange={handlePwdInput}
            value={password}
            variant="outlined"
            autoComplete="on"
            sx={{ backgroundColor: '#fff', [theme.getColorSchemeSelector('dark')]: { backgroundColor: '#1A2027' } }}
          />
          <Button
            fullWidth
            size="large"
            variant="contained"
            type="submit"
            sx={{ mt: 2, mb: 1, height: '56px' }}>
            Login
          </Button>

          <label htmlFor="persist">
            <input
              type="checkbox"
              id="persist"
              hidden
              onChange={handleToggle}
              checked={!!persist}
            />
          </label>
        </Box>
        <Typography sx={{ mt: 4, mb: 3, textAlign: 'center', color: '#6c757d', [theme.getColorSchemeSelector('dark')]: { color: '#cdcdcd' }, fontSize: '.875em' }}>
          &copy; Clean Resources {d.getFullYear() > 2022 ? `2022-${d.getFullYear()}` : `${d.getFullYear()}`}
        </Typography>
      </Box>
    </Container>
  )

  return content
}
export default Login
