import { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import CircularProgress from '@mui/material/CircularProgress'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Unstable_Grid2'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useUpdateUnitMutation } from '../units/unitsApiSlice'
import { useGetDistributorsQuery } from '../distributors/distributorsApiSlice'
import { useModalContext } from '../../hooks/useModalContext'
import { toast } from 'react-toastify'
import CustomTextInput from '../../components/CustomTextInput'
import CustomWindowedSelect from '../../components/CustomWindowedSelect'
import { useTheme } from '@mui/material'

const UpdateUnitForm = () => {
  const theme = useTheme()
  const [updateUnit] = useUpdateUnitMutation()
  const { handleCloseModal, selectedRow: unit } = useModalContext()
  const [pending, setIsPending] = useState(false)
  const { data: distributors, isLoading, isSuccess, isFetching } = useGetDistributorsQuery({}, 'distributorsList', {})
  const [model, setModel] = useState(unit?.model)
  const [awardValue, setAwardValue] = useState(unit?.awardValue)
  const [distributorRef, setDistributorRef] = useState(unit?.distributor)
  const [isActive, setIsActive] = useState(unit?.isActive)

  let distributorOptions
  let defaultDistributor

  if (!(isFetching || isLoading)) {
    distributorOptions = []
    defaultDistributor = {}
  }
  if (isSuccess) {
    distributorOptions = distributors
      .filter((distributor) => distributor.isLicensee)
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((distributor) => ({
        value: distributor,
        label: distributor.name,
        customLabel: `${distributor.address} ${distributor.city}, ${distributor.state} ${distributor.zip}`
      }))

    defaultDistributor = distributors?.find((distributor) => distributor._id === unit?.distributor)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsPending(true)
    try {
      await updateUnit({
        id: unit._id,
        model,
        awardValue: awardValue ? awardValue : '',
        distributor: distributorRef?._id,
        distributorName: distributorRef?.name,
        isActive
      }).unwrap()
      toast.info(
        <>
          Unit <strong>{model}</strong> has been updated.
        </>
      )
    } catch (error) {
      toast.error(
        <>
          Unit <strong>{model}</strong> was not updated. {error.data.message}.
        </>
      )
    }
    handleCloseModal('updateModal')
  }

  const handleDistributorChange = (e) => {
    if (e === null) {
      setDistributorRef(null)
    } else {
      setDistributorRef(e.value)
    }
  }

  const created = unit.createdAt ? new Date(unit.createdAt).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }) : 'N/A'
  const updated = unit.updatedAt ? new Date(unit.updatedAt).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }) : 'N/A'

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ px: 2, py: 1 }}>
        <Grid
          container
          columnSpacing={2}>
          <Grid
            sm={6}
            sx={{ mb: 1 }}>
            <CustomTextInput
              isInModal={true}
              value={model}
              onChange={setModel}
              label="Model"
              name="model"
            />
          </Grid>
          <Grid
            sm={6}
            sx={{ mb: 1 }}>
            <CustomTextInput
              isInModal={true}
              value={awardValue}
              onChange={setAwardValue}
              label="Award Value"
              name="awardValue"
            />
          </Grid>
        </Grid>
        <CustomWindowedSelect
          isInModal={true}
          options={distributorOptions}
          onChange={handleDistributorChange}
          defaultValue={defaultDistributor ? { value: defaultDistributor, label: defaultDistributor?.name, customLabel: `${defaultDistributor?.address} ${defaultDistributor?.city}, ${defaultDistributor?.state} ${defaultDistributor?.zip}` } : null}
          titleLabel="Distributor"
        />
      </Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
        sx={{ px: 2, pb: 2 }}>
        <Typography style={{ fontSize: '13px' }}>
          <strong>Date Created:</strong> {created}
          <br />
          <strong>Date Updated:</strong> {updated}
        </Typography>
        <FormControlLabel
          value="top"
          control={
            <Checkbox
              size="small"
              sx={{ '&.Mui-checked': { color: theme.vars.palette.slate.dark, [theme.getColorSchemeSelector('dark')]: { color: theme.vars.palette.slate.main } }, py: 0 }}
            />
          }
          label="Active"
          labelPlacement="end"
          id="isLicenseeUpdate"
          onChange={(e) => setIsActive(e.target.checked)}
          checked={!!isActive}
        />
        <Stack
          direction="row"
          spacing={1}>
          <Button
            variant="contained"
            size="small"
            color="cancel"
            onClick={() => handleCloseModal('updateModal')}
            type="button">
            Cancel
          </Button>
          {!pending && (
            <Button
              variant="contained"
              size="small"
              type="submit"
              sx={{
                backgroundColor: theme.vars.palette.slate.dark,
                '&:hover': {
                  backgroundColor: theme.vars.palette.slate.darker
                }
              }}>
              Save Changes
            </Button>
          )}
          {pending && (
            <Button
              variant="contained"
              size="small"
              type="button"
              disabled>
              <CircularProgress
                sx={{ color: theme.vars.palette.cancel.light, mr: 0.5 }}
                size={18}
              />
              Saving...
            </Button>
          )}
        </Stack>
      </Stack>
    </form>
  )
}
export default UpdateUnitForm
